export default {
    actions: `Actions`,
    actionError: `Unable to perform this action. Please try again.`,
    active: `Active`,
    add: `Add`,
    addNew: `Add New`,
    addScripture: `Add Scripture`,
    addUser: `Add User`,
    admin: `Admin`,
    back: `Back`,
    cancel: `Cancel`,
    change: `Change`,
    changeStatus: `Change Status`,
    close: `Close`,
    country: `Country`,
    countries: `Countries`,
    countryCode: `Country Code`,
    create: `Create`,
    createdAt: `Created At`,
    createAccount: `Create Account`,
    createPost: `Create Post`,
    dark: `Dark`,
    dashboard: `Dashboard`,
    dateCreated: `Date Created`,
    dayNames: {
        mon: `Monday`,
        tue: `Tuesday`,
        wed: `Wednesday`,
        thu: `Thursday`,
        fri: `Friday`,
        sat: `Saturday`,
        sun: `Sunday`
    },
    days: `Days`,
    deletedSuccessfully: `Deleted Successfully`,
    descriptions: `Descriptions`,
    email: `Email`,
    emptyImageTemplates: `No Image Templates found`,
    emptyScripture: `No Scriptures found`,
    emptyScriptureFiles: `No files found`,
    emptySocialAccounts: `No social accounts found`,
    emptyPost: `No social media post found`,
    emptyPosts: `No social media posts found`,
    emptyUsers: `No Users found`,
    episodes: `Episodes`,
    facebook: `Facebook`,
    delete: `Delete`,
    deleteItem: `Delete Item`,
    deleteSelected: `Delete Selected`,
    devices: `Devices`,
    edit: `Edit`,
    english: `English`,
    file: `File`,
    files: `Files`,
    fileType: `File Type`,
    fileUploadTextOne: `Drop file here or`,
    fileUploadTextTwo: `click to upload`,
    fileUploadTextFileSize: `Upload jpg/png files with less than 10mb`,
    firstName: `First Name`,
    forgotPassword: `Forgot Password`,
    french: `French`,
    fullName: `Full Name`,
    generateFile: `Generate File`,
    hello: `Hello`,
    home: `Home`,
    imageTemplate: `Image Template`,
    imageTemplateDetails: `Image Template Details`,
    imageTemplates: `Image Templates`,
    inactive: `Inactive`,
    info: `Info`,
    invalidEmail: `Invalid Email`,
    invalidPassword: `Invalid Password`,
    language: `Language`,
    lastName: `Last Name`,
    light: `Light`,
    link: `Link`,
    loading: `Loading`,
    login: `Login`,
    message: `Message`,
    myProfile: `My Profile`,
    never: `Never`,
    newHere: `New Here`,
    newMessage: `New Message`,
    name: `Name`,
    no: `No`,
    or: `Or`,
    other: `Other`,
    overview: `Overview`,
    password: `Password`,
    passwordReset: `Password Reset`,
    platform: `Platform`,
    platformSummary: `Platform Summary`,
    plays: `Plays`,
    pleaseWait: `Please wait`,
    posted: `Posted`,
    postedOn: `Posted On`,
    postedDetails: `Post Details`,
    podcastPlatforms: `Our Podcast Platforms`,
    podcastApple: `Apple Podcast`,
    podcastAppleListen: `Listen to Christ Talker on Apple Podcast`,
    podcastSpotify: `Spotify Podcast`,
    podcastSpotifyListen: `Listen to Christ Talker on Spotify Podcast`,
    profile: `Profile`,
    register: `Register`,
    requiredField: `This field is required`,
    reset: `Reset`,
    save: `Save`,
    saveSuccessfully: `Save Successfully`,
    search: `Search`,
    selected: `Selected`,
    scheduled: `Scheduled`,
    scheduleDate: `Schedule Date`,
    scripture: `Scripture`,
    scriptures: `Scriptures`,
    share: `Share`,
    signOut: `Sign Out`,
    social: `Social`,
    socialAccount: `Social Account`,
    socialAccounts: `Social Accounts`,
    socialPlatforms: `Social Platforms`,
    socialPost: `Social Post`,
    socialPosts: `Social Posts`,
    status: `Status`,
    subscriber: `Subscriber`,
    subtitle: `Subtitle`,
    summary: `Summary`,
    system: `System`,
    template: `Template`,
    title: `Title`,
    tryLoginAgain: `Unable to log you in. Please try again.`,
    tryAgain: `Try again`,
    twitter: `Twitter`,
    type: `Type`,
    update: `Update`,
    updatedSuccessfully: `Updated Successfully`,
    updateProfile: `Update Profile`,
    uploadFile: `Upload File`,
    users: `Users`,
    userDetails: `User Details`,
    userProfile: `User Profile`,
    verse: `Verse`,
    version: `Version`,
    view: `View`,
    viewScripture: `View Scripture`,
    viewAll: `View All`,
    welcome: `Welcome`,
    welcomeBackName: `Welcome, {name}!`,
    yes: `Yes`,
    pages: {
        users: {
            selectLanguage: `Please select a preferred language.`,
            updateUser: `Update User`,
            allowNotifications: `Allow Notifications`,
            notificationPreferences: `Please select your notifications preferences`
        },
        scriptures: {
            addScripture: `Add Scripture`,
            addFile: `Add File`,
            selectVersion: `Please select a bible version.`,
            selectDescriptions: `Please choose your bible scripture description`,
            updateScripture: `Update Scripture`,
            scriptureFiles: `Scripture Files`,
            lastPosted: `Last Posted Date`,
            lastSchedule: `Last Schedule Date`,
            unSchedule: `Un-schedule`
        },
        social: {
            authKey: `Auth Key`,
            extendToken: `Extend Token`,
            facebookToken: `Facebook Token`,
            pageId: `Page Id`,
            postOn: `Post on`,
            postNewMessage: `Post New Message`,
            messageDate: `Message Date`,
            selectMessageDate: `Please select a date when you'll like your message to be posted.`,
            addSocialAccount: `Add Social Account`,
            updateSocialAccount: `Update Social Account`,
            socialPlatformsNotification: `Message was post to the following social media platforms`,
            newSocialPlatformsNotification: `Message will be posted to the following social media platforms`
        }
    }
}

export default {
    actions: `Actions`,
    actionError: `Impossible d'effectuer cette action. Veuillez réessayer.`,
    active: `Actif`,
    add: `Ajouter`,
    addNew: `Ajouter un Nouveau`,
    addScripture: `Ajouter un Verset`,
    addUser: `Ajouter un Utilisateur`,
    admin: `Administrateur`,
    back: `Retour`,
    cancel: `Annuler`,
    change: `Changer`,
    changeStatus: `Changer le Statut`,
    close: `Fermer`,
    country: `Pays`,
    countries: `Pays`,
    countryCode: `Code du Pays`,
    create: `Créer`,
    createdAt: `Créé le`,
    createAccount: `Créer un Compte`,
    createPost: `Créer une Publication`,
    dark: `Sombre`,
    dashboard: `Tableau de bord`,
    dateCreated: `Date de Création`,
    dayNames: {
        mon: `Lundi`,
        tue: `Mardi`,
        wed: `Mercredi`,
        thu: `Jeudi`,
        fri: `Vendredi`,
        sat: `Samedi`,
        sun: `Dimanche`
    },
    days: `Jours`,
    deletedSuccessfully: `Supprimé avec succès`,
    descriptions: `Descriptions`,
    email: `Courriel`,
    emptyImageTemplates: `Aucun modèle d'image trouvé`,
    emptyScripture: `Aucun verset trouvé`,
    emptyScriptureFiles: `Aucun fichier trouvé`,
    emptySocialAccounts: `Aucun compte social trouvé`,
    emptyPost: `Aucune publication trouvée`,
    emptyPosts: `Aucune publication trouvée`,
    emptyUsers: `Aucun utilisateur trouvé`,
    episodes: `Épisodes`,
    facebook: `Facebook`,
    delete: `Supprimer`,
    deleteItem: `Supprimer l'élément`,
    deleteSelected: `Supprimer la sélection`,
    devices: `Appareils`,
    edit: `Éditer`,
    english: `Anglais`,
    file: `Fichier`,
    files: `Fichiers`,
    fileType: `Type de Fichier`,
    fileUploadTextOne: `Déposez le fichier ici ou`,
    fileUploadTextTwo: `cliquez pour télécharger`,
    fileUploadTextFileSize: `Téléchargez des fichiers jpg/png de moins de 10 Mo`,
    firstName: `Prénom`,
    forgotPassword: `Mot de passe oublié`,
    french: `Français`,
    fullName: `Nom complet`,
    generateFile: `Générer un Fichier`,
    hello: `Bonjour`,
    home: `Accueil`,
    imageTemplate: `Modèle d'image`,
    imageTemplateDetails: `Détails du modèle d'image`,
    imageTemplates: `Modèles d'images`,
    inactive: `Inactif`,
    info: `Informations`,
    invalidEmail: `Courriel invalide`,
    invalidPassword: `Mot de passe invalide`,
    language: `Langue`,
    lastName: `Nom de famille`,
    light: `Clair`,
    link: `Lien`,
    loading: `Chargement`,
    login: `Connexion`,
    message: `Message`,
    myProfile: `Mon Profil`,
    never: `Jamais`,
    newHere: `Nouveau ici`,
    newMessage: `Nouveau Message`,
    name: `Nom`,
    no: `Non`,
    or: `Ou`,
    other: `Autre`,
    overview: `Aperçu`,
    password: `Mot de passe`,
    passwordReset: `Réinitialisation du Mot de passe`,
    platform: `Plateforme`,
    platformSummary: `Résumé de la Plateforme`,
    plays: `Lectures`,
    pleaseWait: `Veuillez patienter`,
    posted: `Publié`,
    postedOn: `Posté le`,
    postedDetails: `Détails de la Publication`,
    podcastPlatforms: `Nos Plateformes de Podcast`,
    podcastApple: `Podcast Apple`,
    podcastAppleListen: `Écoutez Christ Talker sur Podcast Apple`,
    podcastSpotify: `Podcast Spotify`,
    podcastSpotifyListen: `Écoutez Christ Talker sur Podcast Spotify`,
    profile: `Profil`,
    register: `S'inscrire`,
    requiredField: `Ce champ est obligatoire`,
    reset: `Réinitialiser`,
    save: `Enregistrer`,
    saveSuccessfully: `Enregistré avec succès`,
    search: `Recherche`,
    selected: `Sélectionné`,
    scheduled: `Programmé`,
    scheduleDate: `Date de Programmation`,
    scripture: `Verset`,
    scriptures: `Versets`,
    share: `Partager`,
    signOut: `Déconnexion`,
    social: `Social`,
    socialAccount: `Compte Social`,
    socialAccounts: `Comptes Sociaux`,
    socialPlatforms: `Plateformes Sociales`,
    socialPost: `Publication Sociale`,
    socialPosts: `Publications Sociales`,
    status: `Statut`,
    subscriber: `Abonné`,
    subtitle: `Sous-titre`,
    summary: `Résumé`,
    system: `Système`,
    template: `Modèle`,
    title: `Titre`,
    tryLoginAgain: `Impossible de vous connecter. Veuillez réessayer.`,
    tryAgain: `Réessayer`,
    twitter: `Twitter`,
    type: `Type`,
    update: `Mettre à Jour`,
    updatedSuccessfully: `Mis à jour avec succès`,
    updateProfile: `Mettre à Jour le Profil`,
    uploadFile: `Télécharger un Fichier`,
    users: `Utilisateurs`,
    userDetails: `Détails de l'Utilisateur`,
    userProfile: `Profil de l'Utilisateur`,
    verse: `Verset`,
    version: `Version`,
    view: `Voir`,
    viewScripture: `Voir le Verset`,
    viewAll: `Voir Tout`,
    welcome: `Bienvenue`,
    welcomeBackName: `Bienvenue, {name} !`,
    yes: `Oui`,
    pages: {
        users: {
            selectLanguage: `Veuillez sélectionner une langue préférée.`,
            updateUser: `Mettre à Jour l'Utilisateur`,
            allowNotifications: `Autoriser les Notifications`,
            notificationPreferences: `Veuillez sélectionner vos préférences de notification`
        },
        scriptures: {
            addScripture: `Ajouter un Verset`,
            addFile: `Ajouter un Fichier`,
            selectVersion: `Veuillez sélectionner une version de la Bible.`,
            selectDescriptions: `Veuillez choisir la description de votre verset biblique`,
            updateScripture: `Mettre à Jour le Verset`,
            scriptureFiles: `Fichiers du Verset`,
            lastPosted: `Date de Dernier Post`,
            lastSchedule: `Date de Dernière Programmation`,
            unSchedule: `Annuler la Programmation`
        },
        social: {
            authKey: `Clé d'Authentification`,
            extendToken: `Prolonger le Token`,
            facebookToken: `Token Facebook`,
            pageId: `ID de Page`,
            postOn: `Poster sur`,
            postNewMessage: `Poster un Nouveau Message`,
            messageDate: `Date du Message`,
            selectMessageDate: `Veuillez sélectionner une date à laquelle vous souhaitez que votre message soit posté.`,
            addSocialAccount: `Ajouter un Compte Social`,
            updateSocialAccount: `Mettre à Jour le Compte Social`,
            socialPlatformsNotification: `Le message a été posté sur les plateformes de médias sociaux suivantes`,
            newSocialPlatformsNotification: `Le message sera posté sur les plateformes de médias sociaux suivantes`
        }
    }
}

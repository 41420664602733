import English from '@/translations/common/en-US'
import French from '@/translations/common/fr-FR'

export default defineI18nConfig(() => {
    return {
        legacy: false,
        locale: `en`,
        globalInjection: true,
        messages: {
            en: English,
            fr: French
        }
    }
})
